import React, { useEffect, useState } from 'react';
import { uniq } from 'lodash';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsModalButtonClick } from '@wix/bi-logger-groups-data/v2';
import { groupsModalButtonClickParams } from '@wix/bi-logger-groups-data';

import {
  CloseSmall as CloseIcon,
  Email as EmailIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import {
  selectGroupName,
  selectInviteStatuses,
  selectCurrentUser,
} from 'store/selectors';
import { useDidUpdate } from 'common/hooks';
import { isValidEmail } from 'common/utils/utils';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { TextField } from 'wui/TextField';
import { DialogActions } from 'wui/DialogActions';
import { DialogTitle } from 'wui/DialogTitle';
import { InputDialog } from 'wui/InputDialog';
import { DialogContent } from 'wui/DialogContent';
import { List } from 'wui/List';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemAction } from 'wui/ListItemAction';
import { IconButton } from 'wui/IconButton';

import styles from './styles.scss';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  groupId: string;
  biExtra?: groupsModalButtonClickParams;
}

export function InviteMembersDialog(props: IProps) {
  const { groupId, biExtra, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();

  const { members$ } = useController();

  const status = useSelector(selectInviteStatuses);
  const groupName = useSelector(selectGroupName(groupId));
  const user = useSelector(selectCurrentUser);

  const [invites, setInvites] = useState<string[]>([]);
  const isLoading = invites.some((email) => status[email]?.loading);

  const form = useFormik({
    initialValues: { email: '' },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      addInvite(values.email);
      form.resetForm();
    },
    validate(values) {
      if (!isValidEmail(values.email)) {
        return {
          email: t('groups-web.dialogs.invite-members.email.invalid'),
        };
      }

      return undefined;
    },
  });

  useEffect(() => {
    if (!props.isOpen) {
      form.resetForm();
      setInvites([]);
    }
  }, [props.isOpen]);

  useDidUpdate(() => {
    if (!isLoading) {
      props.onClose();
    }
  }, [isLoading]);

  return (
    <InputDialog
      {...rest}
      className={styles.root}
      aria-labelledby="im-dialog-title"
      paperProps={{ className: styles.paper }}
    >
      <DialogTitle
        id="im-dialog-title"
        title={t('groups-web.dialogs.invite-members.title', {
          count: invites.length,
        })}
        subtitle={t('groups-web.dialogs.invite-members.subtitle', {
          groupName,
        })}
      />
      <Box padding="SP0 SP6" gap="SP4" verticalAlign="top">
        <Box width="100%" direction="vertical">
          <TextField
            error={!!form.errors.email}
            errorMessage={form.errors.email}
            placeholder={t(
              'groups-web.dialogs.invite-members.input.placeholder',
            )}
            {...form.getFieldProps('email')}
          />
        </Box>
        <Button variant="basic" onClick={form.submitForm}>
          {t('groups-web.dialogs.invite-members.add-button.label')}
        </Button>
      </Box>
      <DialogContent divider>
        <List disablePadding>
          {invites.map((email) => (
            <ListItem key={email}>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText title={email} titleProps={{ noWrap: true }} />
              <ListItemAction>
                <IconButton
                  icon={<CloseIcon />}
                  onClick={handleRemoveClick(email)}
                  aria-label={t('groups-web.a11y.close')}
                />
              </ListItemAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          outlined
          variant="basic"
          fullWidth={isMobile}
          onClick={props.onClose}
        >
          {t('groups-web.cancel')}
        </Button>
        <Button
          variant="basic"
          loading={isLoading}
          fullWidth={isMobile}
          onClick={handleSubmit}
          disabled={!invites.length || isLoading}
        >
          {t('groups-web.dialogs.invite-members.submit.label')}
        </Button>
      </DialogActions>
    </InputDialog>
  );

  function handleSubmit() {
    members$.inviteByEmail(groupId, invites);
    bi.report(
      groupsModalButtonClick({
        modal_name: 'add_members',
        group_id: groupId,
        button_name: 'send',
        origin: 'livesite_group_page',
        page_name: 'livesite_group_page',
        user_role: user.role,
        ...(biExtra || {}),
      }),
    );
  }

  function handleRemoveClick(email: string) {
    return function () {
      removeInvite(email);
    };
  }

  function addInvite(email: string) {
    setInvites((emails) => uniq([...emails, email]));
  }

  function removeInvite(email: string) {
    setInvites((emails) => emails.filter((e) => e !== email));
  }
}

InviteMembersDialog.displayName = 'InviteMembersDialog';
